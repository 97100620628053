import * as React from "react";
import "./index.scss";

interface AboutProcessType {
  settings?: any;
}
interface AboutListProcessType {
  title: string;
  icon: string;
  content: string;
  content2?: string;
}
const AboutProcess: React.FC<AboutProcessType> = ({ settings }) => {
  return (
    <section className="about-process">
      <div className="container">
        <h3>{settings?.subTitle}</h3>
        <h2>{settings?.title}</h2>
        <p className="about-process-desc">{settings?.content}</p>
        <p className="about-process-desc">{settings?.content2}</p>
        <div className="about-process-wrap">
          {settings?.list?.map((e: AboutListProcessType, i: number) => {
            return (
              <div className="about-process-item" key={i}>
                {e.icon && (
                  <img
                    src={`${process.env.PUBLIC_URL}/img/icons/${e.icon}.svg`}
                    alt={e.title}
                  />
                )}
                <div className="item-content">
                  <div className="item-title">{e.title}</div>
                  <div className="item-desc">
                    {e?.content && <p>{e.content}</p>}
                    {e?.content2 && <p>{e.content2}</p>}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AboutProcess;
